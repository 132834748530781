angular.module("adminApp")
    .factory('addLicenseAdminModal', ["$uibModal", function ($uibModal) {
        function open(orgInfo, adminView) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    orgInfo: function () {
                        return orgInfo;
                    },
                    adminView: function () {
                        return adminView;
                    }
                },
                templateUrl: "/static/templates/project/addLicenseModal.html",
                controller: "AddLicenseAdminModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("AddLicenseAdminModalCtrl", ["$scope", "$uibModalInstance", "aerosAdminApi", "Notification", "orgInfo", "adminView", function ($scope, $uibModalInstance, aerosAdminApi, Notification, orgInfo, adminView) {
        $scope.isSubmitted = false;
        $scope.adminView = adminView;
        $scope.licenseTypes = [];
        $scope.newLicenseModel = {
            numLicenses: 1,
            licenseType: "",
            key: ""
        };
        getTerms();

        function getTerms() {
            aerosAdminApi.getLicenseTerms().success(function (data) {
                var terms = data.licenseTerms;
                _.each(terms, function (term) {
                    $scope.licenseTypes.push(term);
                });
            });
        }

        $scope.generateLicense = function () {
            $scope.newLicenseModel.term = $scope.newLicenseModel.licenseType.name;
            aerosAdminApi.generateLicense($scope.newLicenseModel)
                .success(function (data) {
                    $scope.newLicenseModel.key = data.licenses[0].id;
                    $scope.isSubmitted = true;
                })
                .error(function (err) {
                    Notification.error("License not generated. " + err);
                    $scope.isSubmitted = false;
                });
        };

        function checkUuid(licenseKey) {
            return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(licenseKey);
        }

        $scope.addLicense = function () {
            if (checkUuid($scope.newLicenseModel.key)) {
                aerosAdminApi.addLicenseToOrganization(orgInfo.id, $scope.newLicenseModel.key)
                    .success(function () {
                        $uibModalInstance.close();
                        Notification.success("License (" + $scope.newLicenseModel.key + ") has been added to " + orgInfo.name);
                    })
                    .error(function (err) {
                        Notification.error("License not added. " + err.message);
                    });
            } else {
                Notification.error("License not added, wrong format.");
            }
        };
    }]);